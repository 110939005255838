
import BaseBean from "@/utils/BaseBean";

export interface IGenCodeDataObj {
    utilInst:GenCodeUtil
    formRef:any
    refMap:Map<String,any>
    applications:Array<string>
    mainTbFields: Array<any>//主表的字段
    mainTbTreeData: Array<any>//主表下拉树
    dialogVisible: boolean//穿梭狂弹出框组件的显示/影藏
    chooseTypeVisible:boolean//代码生成种类选择弹出框的显示/影藏
    titles: Array<string>//穿梭框左右的标题
    transformLeftData: Array<any>//穿梭框左边的数据（每项数据：{key:'',value''}）
    transformRightData: Array<any>//穿梭框右边的数据：已选项。（每项数据：只包含key）
    billTypeTreeData:Array<any>//单据类型数据源
    curOperGrid: string//当前正在操作的明细表格，供穿梭框点击确认的时候用，方便把已选择的穿梭框数据回填到正在操作的表格
    dynamicDetailGridArr:Array<any>//卡片明细表格数组,卡片有多少个明细表格
    compParams: any//代码生成模块组件传给dialog的初始化参数
    yesNoSel: Array<TValueLabel> //卡片显示字段明细表格中是否必填列是一个常量下拉框，该下拉框取值来自于此
    cardFieldType: Array<TValueLabel>  //字段的类型
    fieldType: Array<TValueLabel> //明细可编辑表格可用字段类型
    alignType:Array<TValueLabel>  //表格字段的对其方式
    form: any  //代码生成模块表单属性
    genTypeForm:any //选择要生成文件种类form表单
    //表单验证规则
    rules: TFormRule
}
export default class GenCodeUtil extends BaseBean{
    public dataObj:any
    constructor(proxy:any,dataObj:IGenCodeDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //可用字段类别
    public getFieldTypeArr(needEditor:boolean):Array<TValueLabel>{
        let fieldType=[
            { value: "text", label: "text" },
            { value: "input", label: "input" },
            { value: "unit", label: "unit" },
            { value: "cascader", label: "cascader" },
            { value: "comboTree", label: "comboTree" },
            { value: "comboGrid", label: "comboGrid" },
            { value: "switch", label: "switch" },
            { value: "select", label: "select" },
            { value: "select-v2", label: "select-v2" },
            { value: "datePicker", label: "date-picker" },
            { value: "dateRangePicker", label: "date-range-picker" },
            { value: "inputNumber", label: "el-input-number" },
            { value: "rate", label: "el-rate" },
            { value: "slider", label: "el-slider" }
        ];
        if(needEditor)fieldType.push({ value: "wangEditor", label: "wangEditor" });
        return fieldType;
    }
    //除了第一个表格，其它表格的toolbar都差不多，提取出来
    public buildExChildGridDetailParam(options:any):any{
        return {
            toolBar: [{
                id: "selfAdd",
                title: "增加",
                type: "primary",
                icon: "iconfont icon-zengjia",
                clickEvent: options.clickEvent,
                param: options.detailType
            }],
            canAdd: false,//关闭明细表格自带的新增按钮，因为添加了自己的toolBar，在toolBar中有自己的新增按钮
            canUp:true,
            canDown:true,
            canPage: false,
            queryParam: {
                detailType: options.detailType,//明细的类型由明细表名称来区别
                table: options.detailType,
                mainId: this.dataObj.form.id
            },
            modelMethod: options.utils.Api.buildUrl("/genCode/detail?t="+Math.random()*1000)
        }
    }
    //格式转化，例如DB_SYS_CORP变为 DbSysCorp
    public getJavaNameByDbName(dataBaseName:string):string{
        let tbNameArr=dataBaseName.split("_");
        let modelName="";
        tbNameArr.forEach(function(item,index,arr){
            if(index!=0){
                item=item.toLowerCase();
                item=item.slice(0, 1).toUpperCase() + item.slice(1);
                modelName+=item;
            }
        });
        modelName=modelName.trim();
        return modelName;
    }
    //获取关联子表明细表格中所有的子表以及该子表的字段信息等等，然后重新构建页面动态明细表格
    public reBuildDynamicGrid():void{
        let childTableGridData = this.dataObj.refMap.get('childTable').getDetailData();
        let tableArr = [] as Array<any>, tabDataArr = [] as Array<any>;
        childTableGridData.forEach((item:any)=>{
            if (item.F_TABLE && tableArr.indexOf(item.F_TABLE) == -1) {
                let tbInfo = this.getTbInfoByTbName(this.dataObj.mainTbTreeData, item.F_TABLE);//表数组中找到指定的表
                tabDataArr.push({ tbName: tbInfo.label, tbCaption: tbInfo.caption, tbFields: tbInfo.fields });
                tableArr.push(tbInfo.label);
            }
        });
        this.dataObj.dynamicDetailGridArr=tabDataArr//（改变dataObj.dynamicDetailGridArr就可以重新构建，因为页面用的它作为计算属性）
    }
    //根据表名查询该表的详细信息，不用去后台，因为dataObj.mainTbTreeData有所有的表信息
    public getTbInfoByTbName(tbInfo:any, tbName:string):any{
        for (let i = 0; i < tbInfo.length; i++) {
            if (tbInfo[i].children) {//如果是分类，需要递归调用一下，直到找到需要的表
                let result=this.getTbInfoByTbName(tbInfo[i].children, tbName);
                if(result)return result;
            } else if (tbName == tbInfo[i].label) {
                return tbInfo[i];
            }
        }
    }
    //检查关联子表明细表格是否存在重复的子表名称
    public childTableIsDouble():boolean{
        let childTableGridData = this.dataObj.refMap.get('childTable').getDetailData();
        let tableArr = [] as any;
        childTableGridData.forEach((item:any)=>{
            if (tableArr.indexOf(item.F_TABLE) == -1) tableArr.push(item.F_TABLE);
        });
        return tableArr.length != childTableGridData.length;
    }
    //-----------------穿梭框相关------------------began
    //根据明细表格构建该明细表格拥有的数据库表字段信息(弹出的穿梭框要用到这些字段)detailType其实是数据库表名称
    public getFieldInfoByDetailGridName(detailType:string):any{
        //dataObj.dynamicDetailGridArr里面存放了动态表格的表信息，这个方法主要是给穿梭框用的，
        //而只有动态构建处的表格才会新增的时候弹出穿梭框，所以在dataObj.dynamicDetailGridArr里面找表就可以了。
        let item = this.dataObj.dynamicDetailGridArr.find((item:any)=>{
            if ((item.tbName) == detailType) return item;
        });
        let tbFieldArr = [];
        let tbFields = item.tbFields;
        for (let fieldName in tbFields) {
            tbFieldArr.push({ fieldName: fieldName, fieldCaption: tbFields[fieldName] });
        }
        return tbFieldArr;
    }
    //构建穿梭框内容：左边是指定表的所有字段；右边是已经在明细表格中已经选择的字段
    //构建的时候，先把左右两边的数据清空，然后往左右两边添加数据，左边添加的是全部数据，但是左边会自动去掉右边已存在的项
    public genTransformContent(transformLeftData:any,detailType:string):void{
        if (!this.dataObj.form.tbName) {
            this.utils.Tools.info({ message: "请先选择主表" });
        } else {
            //得到当前操作表格的实例对象，然后用该实例对象得到该表格已经存在的内容，也就是字段
            let existFields=this.dataObj.refMap.get(detailType).getDetailData();
            //先把已选字段数组清空，然后把已经存选择的字段添加进已选数组。这样就不会出现重复添加的情况了。
            this.dataObj.transformRightData.length = 0;
            existFields.forEach((item:any)=>{
                this.dataObj.transformRightData.push(item.F_FIELD);//穿梭框右边的数组只需要提供key就可以
            });
            //把穿梭框左边的数据先清空，然后在把要添加进左边的数据一个个放进左边的穿梭框，和上面一样，这样可以避免穿梭框出现显示重复的数据
            this.dataObj.transformLeftData.length = 0;
            transformLeftData.forEach((item:any)=>{
                let row = { label: item.fieldCaption, key: item.fieldName,disabled:false };
                this.dataObj.transformLeftData.push(row);//穿梭框左边的数组必须包含key和value，也就是每项的全部信息
            });
            this.dataObj.dialogVisible = true;//显示穿梭框的弹出框
        }
    }
    //根据字段名称得到字段的全部信息
    public getFieldByName(totalField:any, fieldName:string):any{
        return totalField.find((item:any)=>{
            if (fieldName == item.fieldName) return item;
        });
    }
    //单击穿梭框的确认按钮，把选择的信息回填到相应的明细表格中,先清空明细表格已有的，再把穿梭框中选择的添加到明细表格中去
    public async doTransTransformSure():Promise<void>{
        let gridInst=this.dataObj.refMap.get(this.dataObj.curOperGrid);//得到当前正在操作的表格实例对象
        await gridInst.clearDetailData();//清空当前表格的行（还有一种处理方式，就是在检查明细表格中是否存在即将要添加的项，不存在则添加，存在则不管）
        switch (this.dataObj.curOperGrid) {
            case "queryFields"://处理列表查询字段信息
                //穿梭框右边存储了选择的字段，但是这些字段信息不全，只有字段名称，所以需要循环这些字段，根据字段名称去总信息里面找出字段的完整信息
                this.dataObj.transformRightData.forEach((item:string)=>{
                    let fieldInfo = this.getFieldByName(this.dataObj.mainTbFields, item);//根据字段名称找出字段完整信息
                    let row = { F_FIELD: item, F_FIELD_CAPTION: fieldInfo.fieldCaption,F_FIELD_TYPE: 'input'};
                    gridInst.addRow(row);
                });
                break;

            case "listFields"://处理列表要显示的字段信息
                this.dataObj.transformRightData.forEach((item:string)=>{
                    let fieldInfo = this.getFieldByName(this.dataObj.mainTbFields, item);
                    let row = {F_FIELD: item, F_FIELD_CAPTION: fieldInfo.fieldCaption,F_TITLE_ALIGN: "center", F_VALUE_ALIGN: "center"};
                    gridInst.addRow(row);
                });
                break;
            case "cardFields"://处理卡片要显示的信息
                this.dataObj.transformRightData.forEach((item:string)=>{
                    let fieldInfo = this.getFieldByName(this.dataObj.mainTbFields, item);
                    let row = {F_FIELD: item, F_FIELD_CAPTION: fieldInfo.fieldCaption,F_REQUIRED:0,F_FIELD_TYPE: 'input'};
                    gridInst.addRow(row);
                });
                break;
            default://肯定是卡片明细
                let tbFieldArr = this.getFieldInfoByDetailGridName(this.dataObj.curOperGrid);
                this.dataObj.transformRightData.forEach((item:string)=>{
                    let fieldInfo = this.getFieldByName(tbFieldArr, item);
                    let row = {F_FIELD: item, F_FIELD_CAPTION: fieldInfo.fieldCaption,F_FIELD_TYPE:'input',F_TITLE_ALIGN: "center", F_VALUE_ALIGN: "center"};
                    gridInst.addRow(row);
                });
                break;
        }
        this.dataObj.dialogVisible = false;
    }
    //-----------------穿梭框相关--------------------end
    //保存之前，把指定明细表格的数据放入到表格的insert数组中，后台是先删除再插入
    public putDataToInsert(gridName:string):void{
        let gridInst=this.dataObj.refMap.get(gridName);//得到表格的实例对象
        let tbData = gridInst.getDetailData();//根据表格实例对象得到表格的所有明细数据
        tbData.forEach((item:any)=>{
            //把表格所有明细数据全部设置为插入状态，后台处理的时候，是先删掉就数据，在插入新的数据，所以前台这样处理，否则后台要考虑删除和更新的情况
            gridInst.setInsert(item);
        });
    }
    //构建明细表格保存数据，把各个明细表格的内容赋值到form表单中去
    public async doSetDetailsJson():Promise<void>{
        //构建所有卡片明细表格的保存数据并赋值给form的一个变量，方便后台接收.每个卡片明细表格数据的key，我们取子表明细的表名称
        let cardDetailTitleArr = [];//所有卡片明细表格要保存的数据，全部放在这个数组里面
        for (let i = 0; i < this.dataObj.dynamicDetailGridArr.length; i++) {
            let gridName=this.dataObj.dynamicDetailGridArr[i]["tbName"];
            await this.putDataToInsert(gridName);
            this. dataObj.form[gridName] =await this.dataObj.refMap.get(gridName).getSaveDetailData();
            cardDetailTitleArr.push(gridName);
        }
        //设置明细表格的保存数据
        await this.putDataToInsert("childTable");
        await this.putDataToInsert("queryFields");
        await this.putDataToInsert("listFields");
        await this.putDataToInsert("cardFields");
        //得到明细表格的保存数据并赋值给form的一个变量，方便后台接收
        this.dataObj.form["childTable"] =await this.dataObj.refMap.get('childTable').getSaveDetailData();//关联子表明细数据构造
        this.dataObj.form["queryFields"] =await this.dataObj.refMap.get('queryFields').getSaveDetailData();//列表查询字段明细数据构造
        this.dataObj.form["listFields"] =await this.dataObj.refMap.get('listFields').getSaveDetailData();//列表显示字段明细数据构造
        this.dataObj.form["cardFields"] =await this.dataObj.refMap.get('cardFields').getSaveDetailData();//卡片显示字段数据构造
        this.dataObj.form["cardDetailTitleArr"] = cardDetailTitleArr;//给后台传递一个固定的名字，用来接收卡片明细的情况
    }
}